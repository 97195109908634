/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
const ROOT = process.env.GATSBY_BACKEND_API_URL;

export function XHTTP(
	route: string,
	params: RequestInit = {},
	ignoreRootConcat = false
): Promise<any> {
	return new Promise((resolve, reject) => {
		const url = `${(ignoreRootConcat ? "" : ROOT) || ""}${route}`;
		fetch(url, params)
			.then((response: Response) => {
				const call = response.status < 400 ? resolve : reject;
				const ct = response.headers.get("Content-Type");
				if (response.status === 204) {
					call();
				} else if (
					ct !== null &&
					(ct.includes("text/plain") || ct.includes("text/csv"))
				) {
					response
						.text()
						.then(call)
						.catch(() => call(response));
				} else {
					response
						.json()
						.then(call)
						.catch(() => call(response));
				}
			})
			.catch((error: any) => {
				if (error && error.json) return error.json().then(reject);
				reject();
			});
	});
}

export function GET_XHTTP(route: string): Promise<any> {
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Paris";
	return XHTTP(route, {
		method: "get",
		headers: {
			"Content-Type": "application/json",
			tz,
		},
	});
}

export function POST_XHTTP(
	route: string,
	body: Record<string, unknown> | Object,
	additionalHeaders: Record<string, string> = {}
): Promise<any> {
	const tz = Intl.DateTimeFormat().resolvedOptions().timeZone || "Europe/Paris";
	return XHTTP(route, {
		method: "post",
		headers: {
			"Content-Type": "application/json",
			tz,
			...additionalHeaders,
		},
		body: JSON.stringify(body),
	});
}
