import React, {createContext, PropsWithChildren, useEffect, useState} from "react";
import {SpringValue, useSpring} from "@react-spring/web";

interface DrawerMenuContextType {
    openMenu: () => void,
    closeMenu: () => void,
    isOpen: boolean,
    animationStyle?: Record<string, SpringValue<string>>,
}

const DrawerMenuContext = createContext<DrawerMenuContextType>({
    openMenu: () => null,
    closeMenu: () => null,
    isOpen: false,
    animationStyle: {},
});

export const DrawerContextProvider = ({children}: PropsWithChildren) => {

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const [animationStyle, api] = useSpring(() => ({
        from: {
            left: '100vw',
            width: '0%',
        },
    }), []);

    useEffect(() => {
        // Disable scroll on body when open.
        // Prevents scrolling below menu
        document.body.style.overflow = isOpen ? "hidden" : "unset";
    }, [isOpen]);

    const openMenu = () => {
        setIsOpen(true);
        api.start({
            to: {
                left: '0',
                width: '100%',
            },
        });
    };
    const closeMenu = () => {
        setIsOpen(false);
        api.start({
            to: {
                left: '100vw',
                width: '0%',
            },
        });
    };

    return (
        <DrawerMenuContext.Provider
            value={{
                openMenu,
                closeMenu,
                isOpen,
                animationStyle,
            }}
        >
            {children}
        </DrawerMenuContext.Provider>
    );
};

export default DrawerMenuContext;