import React from "react";
import './introductionVideoModal.scss';
import SvgClose from "../../../components/ui/icons/SvgClose";

interface Props {
    open: boolean,
    handleClose: () => void,
    videoUrl: string,
}

const VideoModal = ({
    open,
    handleClose,
    videoUrl,
                                }: Props) => {
    return (
        <div
            className={['introductionVideoModal', open ? 'visibleModal' : ''].join(' ')}
        >
            <div className='quitButtonContainer' role='button' onClick={handleClose}>
                <SvgClose />
            </div>
            <div className='modalContent'>
                <iframe
                    title="Vidéo Energic"
                    src={videoUrl}
                    allowFullScreen
                    className='introVideo'
                />
            </div>
        </div>
    );
};

export default VideoModal;