import './src/styles/reset.scss'; // Always first: reset CSS
import './src/styles/fonts.scss'; // Include fonts
import './src/styles/index.scss'; // Global custom CSS

import React from "react";
import {DrawerContextProvider} from "./src/services/hooks/DrawerMenuContext";
import {ContactContextProvider} from "./src/services/hooks/ContactContext";
import {VideoContextProvider} from "./src/services/hooks/VideoContext";

export const wrapRootElement = ({element}) => (
    <DrawerContextProvider>
        <ContactContextProvider>
            <VideoContextProvider>
                {element}
            </VideoContextProvider>
        </ContactContextProvider>
    </DrawerContextProvider>
);