import React, {createContext, PropsWithChildren, ReactNode, useCallback, useEffect, useState} from "react";
import VideoModal from "../../sections/shared/introductionVideoModal/VideoModal";
import Modal from "react-modal";

interface IntroVideoContextType {
    renderVideoModal: () => ReactNode,
    closeModal: () => void,
    openIntroVideoModal: () => void,
    openChallengeVideoModal: () => void,
}

const VideoContext = createContext<IntroVideoContextType>({
    renderVideoModal: () => (
        <></>
    ),
    openIntroVideoModal: () => {},
    openChallengeVideoModal: () => {},
    closeModal: () => {},
});

// Give react-modal a ref to the root element
Modal.setAppElement(`#___gatsby`);

const introVideoUrl = 'https://www.youtube.com/embed/wmAb7z7nC3E?autoplay=1';
const challengeIntroVideoUrl = 'https://www.youtube.com/embed/Hlo8YTW4Xok?autoplay=1';

export const VideoContextProvider = ({children}: PropsWithChildren) => {
    const [open, setOpen] = useState<boolean>(false);
    const openModal = () => setOpen(true);
    const closeModal = () => setOpen(false);

    // Trick to use autoplay from youtube in iframe
    const [videoUrl, setVideoUrl] = useState<string>('');

    useEffect(() => {
        //document.body.style.overflow = open ? "hidden" : "unset";
        //setVideoUrl(open ? introVideoUrl : '');
    }, [open]);

    const openIntroVideoModal = () => {
        setVideoUrl(introVideoUrl);
        setOpen(true);
    };

    const openChallengeVideoModal = () => {
        setVideoUrl(challengeIntroVideoUrl);
        setOpen(true);
    };

    const handleCloseModal = () => {
        setVideoUrl('');
        setOpen(false);
    }

    const renderVideoModal = useCallback(() => (
        <VideoModal
            open={open}
            handleClose={handleCloseModal}
            videoUrl={videoUrl}
        />
    ), [videoUrl, open]);

    return (
        <VideoContext.Provider
            value={{
                renderVideoModal,
                closeModal,
                openIntroVideoModal,
                openChallengeVideoModal,
            }}
        >
            {children}
        </VideoContext.Provider>
    );
};

export default VideoContext;